<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderMenuReportPanel
        :title="'CUSTOMER'"
        :filter="filter"
        placeholder="Search Name, Telephone, Email"
        @sidebar="sidebarFilter"
        :btnFilter="true"
        :btnExport="true"
        @search="handleSearch"
        @confirmExport="getFilterExport"
        importPath="/customer"
        @downLoadTemplate="downLoadTemplate"
      />
      <div class="bg-white border-red px-1 px-sm-3 mt-3">
        <TableListReportCustomer
          :fields="fields"
          :items="items"
          :isBusy="isBusy"
          :showing="showing"
          :showingTo="showingTo"
          :rows="rows"
          :pageOptions="pageOptions"
          :filter="filter"
          @page="filterPage"
          @handleChangeTake="handleChangeTake"
        />
      </div>
    </div>
    <SidebarFilterReportPanel
      ref="sidebarFilter"
      :filter="filter"
      placeholder="Search Name, Telephone, Email"
      :isConsentList="isConsentList"
      :memberLevelList="memberLevelList"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
    />
    <ModalLoading ref="LoadingModal" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import ModalLoading from "@/components/modal/ModalLoading";
import HeaderMenuReportPanel from "@/components/report/header/HeaderMenuReportPanel";
import SidebarFilterReportPanel from "@/components/report/header/SidebarFilterReportPanel";
import TableListReportCustomer from "@/components/report/customer/TableListReportCustomer";
export default {
  components: {
    OtherLoading,
    HeaderMenuReportPanel,
    SidebarFilterReportPanel,
    TableListReportCustomer,
    ModalLoading,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "first_name_th",
          label: "Customer Name",
          thStyle: {
            width: "20% !important",
          },
        },
        {
          key: "member_level_name",
          label: "Tier",
          thStyle: {
            width: "15% !important",
          },
        },
        {
          key: "telephone",
          label: "Telephone",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "point_total",
          label: "Point",
        },
        {
          key: "created_time",
          label: "Register Date",
        },
        {
          key: "grand_total",
          label: "Grand Total",
        },
        {
          key: "is_consent",
          label: "Consent Status",
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      isBusy: true,
      rows: 0,
      showing: 1,
      showingTo: 10,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        member_level_id: null,
        search: null,
        page: 1,
        take: 10,
        is_consent: null,
      },
      isConsentList: [
        { id: 1, name: "ยินยอม" },
        { id: 2, name: "รอการยินยอม" },
        { id: 0, name: "ไม่ยินยอม" },
        { id: 3, name: "Fail Consent 1" },
        { id: 4, name: "Fail Consent 2" },
        { id: 5, name: "Fail Consent 3" },
        { id: 6, name: "ถอดถอน" },
        { id: 7, name: "ระงับ" },
      ],
      memberLevelList: [],
      filterExportFile: {
        member_level_id: null,
        search: null,
        is_consent: null,
      },
    };
  },
  async created() {
    await this.getReportCustomer();
    await this.getMemberLevelList();
  },
  methods: {
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    clearFilter() {
      this.filter = {
        member_level_id: null,
        search: null,
        page: 1,
        take: 10,
        is_consent: null
      };
    },
    async getReportCustomer() {
      this.isBusy = true;
      await this.$store.dispatch("getReportCustomerList", this.filter);
      const data = this.$store.state.report.reportCustomerList;
      this.items = data.detail;
      this.rows = data.total_count;
      this.isBusy = false;
    },
    async getMemberLevelList() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevelList = data.detail;
      }
    },
    getFilterExport() {
      this.filterExportFile.member_level_id = this.filter.member_level_id;
      this.filterExportFile.search = this.filter.search;
      this.filterExportFile.is_consent = this.filter.is_consent;
      this.exportReportCustomer();
    },
    async exportReportCustomer() {
      this.$refs.LoadingModal.show();
      await this.$store.dispatch(
        "exportReportCustomerList",
        this.filterExportFile
      );
      const data = this.$store.state.report.respExportCustomer;
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `ReportCustomer-` + dateExcel + `.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        alert("Export fail");
      }
      this.$refs.LoadingModal.hide();
    },
    filterPage(filter) {
      this.filter = filter;
      this.getReportCustomer();
    },
    handleSearch(filter) {
      this.filter = filter;
      this.getReportCustomer();
    },
    searchAll(filter) {
      this.filter = filter;
      this.getReportCustomer();
    },
    handleChangeTake(filter) {
      this.filter = filter;
      this.getReportCustomer();
    },
    async downLoadTemplate() {
      this.isLoading = true;
      await this.$store.dispatch("downloadTemplateCustomer");
      const data = this.$store.state.importFile.downloadTransaction;
      var fileURL = window.URL.createObjectURL(new Blob([data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      this.isLoading = false;
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `Customer-Template-List-` + dateExcel + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-td-checkbox {
  width: 5%;
}
</style>
