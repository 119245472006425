<template>
  <b-sidebar
    id="sidebarFilter"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilter"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
      <b-input-group>
        <b-form-input
          type="search"
          class="search-bar"
          :placeholder="placeholder"
          v-model="filter.search"
        >
        </b-form-input>
      </b-input-group>
      <div class="mt-3">
        <b-form-group label="Member Tier">
          <b-form-checkbox-group
            v-model="memberSelected"
            :options="memberLevelList"
            value-field="id"
            text-field="name"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
      <div>
        <b-form-group label="Status Consent">
          <b-form-checkbox-group
            v-model="consentSelected"
            :options="isConsentList"
            value-field="id"
            text-field="name"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
    placeholder: {
      required: true,
      type: String,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
    memberLevelList: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      memberSelected: [],
      consentSelected: [],
    };
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    clearFilter() {
      this.memberSelected = [];
      this.consentSelected = [];
      this.$emit("clearFilter");
    },
    submitAction() {
      this.filter.page = 1;
      if (this.memberSelected.length > 0) {
        this.filter.member_level_id = this.memberSelected;
      } else {
        this.filter.member_level_id = null;
      }
      if (this.consentSelected.length > 0) {
        this.filter.is_consent = this.consentSelected;
      } else {
        this.filter.is_consent = null;
      }
      this.$emit("searchAll", this.filter);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
::v-deep fieldset.form-group {
  legend {
    font-weight: 700;
  }
  div[role="group"] {
    padding-left: 0.5rem;
  }
}
</style>
